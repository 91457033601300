import React from "react"
import css from "@styled-system/css"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { StaticQuery, graphql } from "gatsby"
import ScrollChanger from "react-scroll-changer"

import { Section, QuoteSection, CopySection } from "components"

import { Smiley, Room } from "vectors"

import ComponentSection from "./_components/_componentSection"

import smileyLottie from "../../../static/lottie/smileyLottie.json"

const ComponentsPage = () => (
  <StaticQuery
    query={graphql`
      {
        page: datoCmsComponentsPage {
          seoMetaTags {
            ...GatsbyDatoCmsSeoMetaTags
          }
          seoKeywords
          heroTitle
          heroDescription
          partitions {
            id
            name
            partitionType
            description
            image {
              url
            }
            video {
              url
            }
          }
          doors {
            id
            name
            partitionType
            description
            image {
              url
            }
            video {
              url
            }
          }
          customerServiceTitle
          customerServiceSubtitle
          closingTitle
          closingPoint1Title
          closingPoint1Description
          closingPoint2Title
          closingPoint2Description
          closingPoint3Title
          closingPoint3Description
        }
      }
    `}
    render={data => (data.page) ? (
      <>
        <HelmetDatoCms seo={data.page.seoMetaTags}>
          <meta name="keywords" content={data.page.seoKeywords} />
        </HelmetDatoCms>
        <Section
          graphic={Room}
          title={data.page.heroTitle}
          content={data.page.heroDescription}
          bg="primary"
          top={120}
        />
        <ComponentSection
          title={"Partition Systems"}
          items={data.page.partitions}
          type={`Partitions`}
        />
        <ComponentSection
          title={"Doors"}
          items={data.page.doors}
          type={`Doors`}
        />
        <div
          css={css({
            display: "none",
            "@supports (-ms-ime-align:auto)": {
              display: "block",
            },
            "@media screen and (-ms-high-contrast: none)": {
              display: "block",
            },
          })}
        >
          <Section
            graphic={Smiley}
            title={data.page.customerServiceTitle}
            subtitle={data.page.customerServiceSubtitle}
            bg="backgroundReverse"
            color="textReverse"
          />
        </div>
        <div
          css={css({
            "@supports (-ms-ime-align:auto)": {
              display: "none",
            },
            "@media screen and (-ms-high-contrast: none)": {
              display: "none",
            },
          })}
        >
          <ScrollChanger
            children={
              <Section
                lottie={smileyLottie}
                lottieLoop={60}
                lottieCss={{
                  marginLeft: ["-33%", "0"],
                }}
                title={data.page.customerServiceTitle}
                subtitle={data.page.customerServiceSubtitle}
                bg="backgroundReverse"
                color="textReverse"
                id="smileySectionComponent"
              />
            }
            start={0}
            end={1}
            length={1}
            attached={false}
          />
        </div>
        {data.page.closingTitle && (
          <QuoteSection quote={data.page.closingTitle} />
        )}
        {(data.page.closingPoint1 ||
          data.page.closingPoint1Description ||
          data.page.closingPoint2 ||
          data.page.closingPoint2Description ||
          data.page.closingPoint3 ||
          data.page.closingPoint3Description) && (
            <CopySection
              section1={{
                title: data.page.closingPoint1Title,
                text: data.page.closingPoint1Description,
              }}
              section2={{
                title: data.page.closingPoint2Title,
                text: data.page.closingPoint2Description,
              }}
              section3={{
                title: data.page.closingPoint3Title,
                text: data.page.closingPoint3Description,
              }}
            />
          )}
      </>
    ) : null}
  />
)

export default ComponentsPage
