import React from "react"
import css from "@styled-system/css"

import { useContact } from "contexts/contact"

import { Box, Container, Flex, H2, H6, Button, Text } from "components"

function Item({ item, type }) {
  const { setContactOpen, setProduct, setReason } = useContact()

  return (
    <Box height="100%" minHeight={350} p={3}>
      <Box
        position="relative"
        height="100%"
        minHeight={350}
        bg="backgroundReverse"
        css={css({
          backgroundImage:
            item.image &&
            "linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(" +
              item.image.url +
              "?fit=crop&crop=center&max-w=800&max-h=800&auto=compress)",
          backgroundPosition: "center",
          backgroundSize: "cover",
        })}
      >
        <Box position="absolute" bottom={0} left={0} p={4}>
          <H6 children={item.partitionType} mb={2} color="textReverse" />
          <H2 children={item.name} color="textReverse" />
          <Box maxWidth={420} mt={2} mb={4}>
            <Text children={item.description} color="textReverse" />
          </Box>
          <Flex
            alignItems="center"
            css={css({
              "> *:not(:last-child)": {
                mr: 3,
              },
            })}
          >
            {item.video && (
              <a
                href={item.video.url}
                target="_blank"
                rel="noopener noreferrer"
                css={css({
                  ":hover ~ .video-text": {
                    display: "block",
                  },
                })}
              >
                <svg
                  width={50}
                  height={50}
                  fill="none"
                  css={css({ verticalAlign: "top" })}
                >
                  <path
                    d="M25 50c13.807 0 25-11.193 25-25S38.807 0 25 0 0 11.193 0 25s11.193 25 25 25z"
                    fill="#FCD06B"
                  />
                  <path
                    d="M21.525 16.318v8.853l8.853-4.427-8.853-4.426z"
                    stroke="#fff"
                    strokeWidth={2}
                    strokeMiterlimit={10}
                  />
                  <path
                    d="M12.286 20.454C12.286 13.58 17.866 8 24.74 8c6.874 0 12.453 5.58 12.453 12.454 0 6.874-5.58 12.454-12.453 12.454v5.43M24.74 40.928V44"
                    stroke="#fff"
                    strokeWidth={2}
                    strokeMiterlimit={10}
                  />
                </svg>
              </a>
            )}
            <button
              onClick={() => {
                setContactOpen(true)
                setReason(`I would like technical specifications`)
                setProduct(type + ` - ` + item.name)
              }}
              css={css({
                m: 0,
                p: 0,
                border: 0,
                bg: "transparent",
                cursor: "pointer",
                ":hover ~ .specs-text": {
                  display: "block",
                },
              })}
            >
              <svg
                width={50}
                height={50}
                fill="none"
                css={css({ verticalAlign: "top" })}
              >
                <path
                  d="M25 50c13.807 0 25-11.193 25-25S38.807 0 25 0 0 11.193 0 25s11.193 25 25 25z"
                  fill="#FCD06B"
                />
                <path
                  d="M27.04 39.71H14.724V12h21.555v18.473L27.04 39.71z"
                  stroke="#fff"
                  strokeWidth={2}
                  strokeMiterlimit={10}
                />
                <path
                  d="M36.278 30.473H27.04v9.237M17.804 18.155h15.392M17.804 24.318h15.392"
                  stroke="#fff"
                  strokeWidth={2}
                  strokeMiterlimit={10}
                />
              </svg>
            </button>
            <Box
              maxWidth={240}
              className="specs-text"
              css={css({ display: "none" })}
            >
              <Text
                children={`Contact your branch to download the technical specs for this component.`}
                fontSize={1}
                color="textReverse"
              />
            </Box>
            <Box
              maxWidth={240}
              className="video-text"
              css={css({ display: "none" })}
            >
              <Text
                children={`Check out our video to see how this component works.`}
                fontSize={1}
                color="textReverse"
              />
            </Box>
          </Flex>
        </Box>
      </Box>
    </Box>
  )
}

const ComponentSection = ({ title, items, type }) => (
  <Box as="section" my={[5, 6]}>
    <Container>
      <H2 children={title} mb={4} />
      {items && (
        <Box
          css={css({
            display: "grid",
            gridTemplateColumns: ["1fr", null, null, "1fr 1fr 1fr 1fr 1fr 1fr"],
            gridAutoRows: "414px",
            m: -3,
            overflow: "hidden",
            "@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none)": {
              display: "-ms-grid",
              msGridColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
              msGridRows: "auto",
            },
          })}
        >
          <>
            {items.map((item, index) => (
              <Box
                key={title + "items" + index}
                css={css({
                  ":nth-child(1)": {
                    msGridColumn: 1,
                    msGridColumnSpan: [1, null, null, 3],
                    gridColumn: [1, null, null, "1 / span 3"],
                    msGridRow: 1,
                    msGridRowSpan: [1, null, null, 2],
                    gridRow: [1, null, null, "span 2"],
                  },
                  ":nth-child(2)": {
                    msGridColumn: [1, null, null, 4],
                    msGridColumnSpan: [1, null, null, 3],
                    gridColumn: [1, null, null, "4 / span 3"],
                    msGridRow: [2, null, null, 1],
                    msGridRowSpan: 1,
                    gridRow: [2, null, null, "span 1"],
                  },
                  ":nth-child(3)": {
                    msGridColumn: [1, null, null, 4],
                    msGridColumnSpan: [1, null, null, 3],
                    gridColumn: [1, null, null, "4 / span 3"],
                    msGridRow: [3, null, null, 2],
                    msGridRowSpan: 1,
                    gridRow: [3, null, null, "span 1"],
                  },
                  ":nth-child(4)": {
                    msGridColumn: 1,
                    msGridColumnSpan: [1, null, null, 2],
                    gridColumn: [1, null, null, "1 / span 2"],
                    msGridRow: [4, null, null, 3],
                    msGridRowSpan: [1, null, null, 2],
                    gridRow: [4, null, null, "span 2"],
                  },
                  ":nth-child(5)": {
                    msGridColumn: [1, null, null, 3],
                    msGridColumnSpan: [1, null, null, 2],
                    gridColumn: [1, null, null, "3 / span 2"],
                    msGridRow: [5, null, null, 3],
                    msGridRowSpan: [1, null, null, 2],
                    gridRow: [5, null, null, "span 2"],
                  },
                  ":nth-child(6)": {
                    msGridColumn: [1, null, null, 5],
                    msGridColumnSpan: [1, null, null, 2],
                    gridColumn: [1, null, null, "5 / span 2"],
                    msGridRow: [6, null, null, 3],
                    msGridRowSpan: [1, null, null, 2],
                    gridRow: [6, null, null, "span 2"],
                  },
                })}
              >
                <Item item={item} type={type} />
              </Box>
            ))}
          </>
        </Box>
      )}
    </Container>
  </Box>
)

export default ComponentSection
